/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
#wrapper > footer .content,
#wrapper > footer .rich-text {
  font-size: 1em;
  margin-right: 1em;
}

#wrapper > footer img {
  height: 30px;
}

#wrapper > footer .link-list > .component-content li a,
#wrapper > footer p {
  font-size: 14px;
}

#wrapper > footer .column-splitter > div:last-child {
  display: flex;
  flex-flow: row-reverse;
}

@media (max-width: 767px) {
  #wrapper > footer .column-splitter > div:last-child {
    flex-wrap: wrap;
    justify-content: center;
  }
  #wrapper > footer .column-splitter > div:last-child > :nth-child(3), #wrapper > footer .column-splitter > div:last-child > :nth-child(2) {
    width: 35%;
    margin: 15px 0;
  }
  #wrapper > footer .column-splitter > div:last-child > :nth-child(1) {
    width: 100%;
    order: 3;
  }
}

#wrapper > footer .row-splitter > div:last-child .row {
  padding-top: 2em;
}

#wrapper > footer .row-splitter > div:last-child .row p {
  padding-left: 25px;
}

#wrapper > footer .link-list > .component-content li {
  margin-right: 1.5em;
}

@media (max-width: 767px) {
  #wrapper > footer .link-list > .component-content li {
    margin-right: 0em;
  }
  #wrapper > footer .link-list > .component-content li a {
    padding-left: 0;
  }
}

#wrapper > footer .link-list > .component-content li a {
  text-decoration: underline;
}

@media (max-width: 767px) {
  #wrapper > footer .link-list > .component-content li {
    margin: 1em 0;
  }
}

@media (max-width: 767px) {
  #wrapper > footer .rich-text {
    margin: auto;
  }
  #wrapper > footer .link-list {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  #wrapper > footer .link-list ul {
    flex-direction: column;
  }
}

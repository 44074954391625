@import "abstracts/vars";
@import "abstracts/mixins";

#wrapper > footer {
    .content, 
    .rich-text {
        font-size: 1em;
        margin-right: 1em;
    }

    img {height: 30px;}

    .link-list > .component-content li a,
    p {
        font-size: 14px;
    }

    .column-splitter > div:last-child{
            display: flex;
            flex-flow: row-reverse;

            @include max-screen(sm){
                flex-wrap: wrap;
                justify-content: center;
                > :nth-child(3), > :nth-child(2){
                    width:35%;
                    margin: 15px 0;
                }
                > :nth-child(1){
                    width:100%;
                    order:3;
                }
    
            }
    }

    .row-splitter > div:last-child .row{
        padding-top: 2em;
        p {padding-left: 25px}
    }

    .link-list > .component-content li{ 
        margin-right: 1.5em;

        @include max-screen(sm) {
            margin-right: 0em; 

            a {
                padding-left: 0;
            }
        }

        a {
         text-decoration: underline;
        }

        @include max-screen(sm) {
            margin: 1em 0;
        }
    }

    @include max-screen(sm) {
        .rich-text {
            margin: auto;
        }

        .link-list {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .link-list ul {
            flex-direction: column;
        }
    }
}